export const enums = {
    // encryption: "qXS0k2t1kM2oMe++nk17p73KLwZsb0vmyH5WcUaFJaym4PhyKQNAz+4Ze1XaceHunI+PoRWbEZMBtfRpCuzaVQ==",
    encryption_meta: "M2QilGtVp+FKP+LQ/3Arpw==",
    aseKey: "12345678",
    // signSaltKey: "h*Ut0AWdh7YQCiAnwAc%NAsB8eh^qhAIytN7DQTJ"
    signSaltKey: "RSw2Nyn$I9i^z6b1ytkC%MIc6GrdOtaVjvk^!TR@L%f8VihmM5uX@HVY0!DR^DfR",
    signSaltKey_meta: "LpIUA29OZkPJ4YwI1Uho+rU/FPK6dPbMmma5iuCqPRgRIL1RruMOgXSo/oSHTyJ/hNNLp2VF9o9Pp7RDffRmCLMKI1LbEGmaGX9enzNL/gs=",
    signSaltKey_yzl: "@$p#p051r3gu0WPIz&guvxdq5SYdJiA*jpmqxfGaINkGYg@TjcTz5ax&Kr%jzkGZ",
    sha256Key: "cnaHXu3A@*&D2279ZA8H&h0vhmTpfhW@",
    sha256Key_deal: "jbtbSV0*^D9qZvV016$M&A0sdZVuFr0B", // 交易密码key
    signSaltKey_dkPlush: "4ZqvV3Y$RUnT4pZuEhSWs4ZRNjDFgpySyX9#bL595gKUGbZjk%HHynnZq&ZtfrqY",
    // 测试APPID
    // APPID:"2020100111110001",
    // 正式APPID
    APPID: "2020100111110001",
    atoshiAppAddress: "https://share.jdhx.run/#/xiazaiye?from=singlemessage",
    wangzhuanAppAddress: "https://img.leshuapro.com/apk/leshua.apk",
    DeTokAddress: "https://img.leshuapro.com/apk/DeTok.apk",
    apkUrl_flutter: "https://image.leshua.pro/flutter/apk/DeTok.apk",
    appStore: "https://apps.apple.com/cn/app/%E4%B9%90%E5%94%B0-detok/id6444339697", // app store链接
    google: "https://play.google.com/store/apps/details?id=com.good.sliver", // 谷歌链接
    dkPlush: "https://image.leshua.pro/flutter/apk/DeTok.apk", // 国际版DeTok链接
};


export const enumsImages = {
    addressZh: "https://video2.juhaowu.cn/static-web/mobile_img/mobile_zh",
    addressEn: "https://video2.juhaowu.cn/static-web/mobile_img/mobile_en",
    addressCommon: "https://video2.juhaowu.cn/static-web/mobile_img/common/img"
};

export const enumsZh = {
    address: "https://official.juhaowu.cn",
    // banner视频
    atoscnMp4: "https://image.juhaowu.cn/cn_video/video/atos-cn.mp4",
    atoscnMp4480: "https://image.juhaowu.cn/cn_video/video/atoshi-480p.mp4",
    atoscnMp4720: "https://image.juhaowu.cn/cn_video/video/atoshi-720p.mp4",
    atoscnMp41080: "https://image.juhaowu.cn/cn_video/video/atoshi-1080p.mp4",
    // ceo视频
    ceoAtoscnMp4: "https://image.juhaowu.cn/cn_video/video/CNATOSHI.mp4",
    ceoAtoscnMp4480: "https://image.juhaowu.cn/cn_video/video/CNATOSHI480p.mp4",
    ceoAtoscnMp4720: "https://image.juhaowu.cn/cn_video/video/CNATOSHI720p.mp4",
    ceoAtoscnMp41080: "https://image.juhaowu.cn/cn_video/video/CNATOSHI1080p.mp4",
    // 正式
    downloadUrl: "https://share-plus.juhaowu.net/#/xiazaiye?from=singlemessage",
    // 测试
    // downloadUrl:"http://appone.libangtianbo.net.cn/#/xiazaiye",
    // h5 下载海外版 正式
    seaAtoshiDownloadUrl: "https://www.atoshi.org/globaldownload/#/?language=zh",
    // 乐唰下载
    leshuaDownload: "https://leshua.info",
    // 游戏下载
    gamesDownload: "https://game-img.atoshi.mobi/official/box_mobile.jpg",
    // 区块链地址
    chainAtoshiUrl: "https://scan.atoverse.info/"
};