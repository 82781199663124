<template>
  <div class="register" id="register" style="color: #1b1b1b">
    <van-overlay :show="browerFlag" @click="controlBrower">
      <img :src="languageConfig[language].wechat" class="img_brower" />
    </van-overlay>
    <van-overlay :show="showOverlay" />
    <div class="center">
      <ul class="registerTop">
        <li class="flexable">
          <img src="@/common/img/register/logo.png" alt="" style="width: 16vw;">
          <p class="linearFont">
            {{languageConfig[language].topText.title1}}<br>
            {{languageConfig[language].topText.title2}}
          </p>
        </li>
        <li style="font-size: 4vw;font-weight: bold;line-height: 7.2vw;margin-top: 8vw;color: #333;">
          {{languageConfig[language].topText.text1}}
          <span class="colorB">{{languageConfig[language].topText.text2}}</span>
          {{languageConfig[language].topText.text3}}
          <span class="colorB">{{languageConfig[language].topText.text4}}</span>
          {{languageConfig[language].topText.text5}}
          <span class="colorB">{{languageConfig[language].topText.text6}}</span> 
        </li>
      </ul>
      <div class="registerInput">
        <div style="padding-bottom: 10px">
          <van-field
            ref="inputMobile"
            v-model="authInfo.userMobile"
            clearable
            label=""
            :placeholder="appName == 'dkPlush' ? languageConfig[language].placeholder_dp : languageConfig[language].placeholder"
            @focus="fieldFocus"
            @blur="fieldBlur"
          />
          <van-field
            v-model="params.verCode"
            type="text"
            label=""
            :placeholder="languageConfig[language].placeholder1"
            @focus="fieldFocus"
            @blur="fieldBlur"
          />
          <span
            class="checkCode_dp"
            @click="checkStatus"
            v-if="codeShow"
            id="TencentCaptchaBtn"
            >{{ languageConfig[language].button }}</span
          >
          <van-field
            v-model="params.password"
            :type="pwdType"
            label=""
            :placeholder="languageConfig[language].placeholder2"
            @focus="fieldFocus"
            @blur="fieldBlur"
            @click-right-icon="pwdClick"
            :right-icon="pwdIcon"
          />
          <van-field
            v-model="params.checkPassword"
            :type="pwdType1"
            label=""
            :placeholder="languageConfig[language].placeholder3"
            id="rollTop"
            @focus="fieldFocus"
            @blur="fieldBlur"
            @click-right-icon="pwdClick1"
            :right-icon="pwdIcon1"
          />
          <span class="checkpassword" v-if="checkpassword">{{
            languageConfig[language].password
          }}</span>
          <van-field type="password" style="display: none" />
          <!-- <div class="item inviteCode">
            <span>{{ languageConfig[language].inviteDes }}：</span>
            <span>{{ inviteCode }}</span>
          </div> -->
          <van-field
            v-model="inviteCode"
            type="text"
            :label="languageConfig[language].inviteDes"
            label-width="30vw"
            clearable
            :placeholder="languageConfig[language].inviteP"
            id="rollTop"
            @focus="fieldFocus"
            @blur="fieldBlur"
            disabled
            v-if="inviteCode"
          />
        </div>
        <span class="time" v-if="!codeShow"
          >{{ time }}{{ languageConfig[language].buttons }}</span
        >
        <!-- <div class="footer" v-show="focus"> -->
        <div class="footer">
          <van-checkbox
            v-model="checked"
            checked-color="#FD504D"
            style="width: 3.2vw; height: 3.2vw"
          ></van-checkbox>
          <span>
            {{ languageConfig[language].text }}
            <span :class="{'note': appName != 'dkPlush', 'note_dp': appName == 'dkPlush'}" @click="jump('userAgreement')">{{
              languageConfig[language].text1
            }}</span>
            {{ languageConfig[language].text2 }}
            <span :class="{'note': appName != 'dkPlush', 'note_dp': appName == 'dkPlush'}" @click="jump('privacyPolicy')">{{
              languageConfig[language].text3
            }}</span>
          </span>
        </div>
        <p :class="{'button': true, 'btn_yes': appName != 'dkPlush', 'btn_dp': appName == 'dkPlush'}" @click="register" v-if="!newbie">
          {{ languageConfig[language].register }}
        </p>
        <!-- <span class="editClass" @click="inputFocus">点击修改</span> -->
      </div>

      
    </div>
    <van-overlay :show="show" class="dialog">
      <div class="wrapper" @click.stop>
        <div class="tips">
          <img :src="download" />
          <h3>{{ languageConfig[language].Reminder }}</h3>
          <p>{{ languageConfig[language].registerA }}</p>
          <p :class="appName == 'dkPlush' ? 'downDp' : 'down'" @click="open">{{ languageConfig[language].dSoon }}</p>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showDownload" class="dialog">
      <div class="wrapper" @click.stop>
        <div class="tips">
          <img :src="cgImg" />
          <h3>{{ languageConfig[language].success }}</h3>
          <!-- <p>您已经注册过“乐唰”APP，无需再次注册！点击下方，下载“乐唰”APP</p> -->
          <p :class="appName == 'dkPlush' ? 'downDp' : 'down'" @click="open">{{ languageConfig[language].dSoon }}</p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from "vant";
// import server from "@/server/index.js";
import { enums } from "@/utils/enum.js";
import { handleSysLang } from "@/utils";
// import CryptoJS from "crypto-js";
import md5 from "js-md5";
import $ from "jquery";
const baseApi = "https://api.leshuapro.com";
// const baseApi = "http://newsapp-test.leshuapro.com";
var captcha1;
export default {
  name: "registerMobile",
  data() {
    return {
      browerFlag: false,
      show: false,
      authInfo: {
        userMobile: "",
        userHead: "",
        isActivated: "",
        sKey: "",
      },
      time: 59,
      codeShow: true,
      checkpassword: false,
      params: {
        password: "",
        checkPassword: "",
        ticket: "",
        randstr: "",
        verCode: "",
      },
      inviteCode: null,
      tel: "",
      step: 1,
      newbie: false, // 新用户
      state: "",
      showOverlay: false,
      disabled: false,
      showDownload: false,
      checked: false,
      languageConfig: {
        "zh-CN": {
          title: "注册",
          logo: require("@/common/img/logo_en.png"),
          name: require("@/common/img/name_en.png"),
          placeholder: "输入手机号/邮箱",
          placeholder_dp: "输入邮箱",
          placeholder1: "请输入验证码",
          placeholder2: "请设置密码",
          placeholder3: "请再次输入密码",
          button: "获取验证码",
          buttons: "s重新获取",
          inviteDes: "邀请码",
          inviteP: "请输入邀请码",
          register: "注册",
          ios: "IOS版本研发中,敬请期待!",
          text: "阅读并同意",
          text1: "【用户协议】",
          text2: "和",
          text3: "【隐私政策】",

          password: "两次密码输入不一致，请重新输入",
          Reminder: "提示",
          registerA:
            "您已经注册过“DeTok”账号，无需再次注册！点击下方，下载“DeTok”APP",
          dSoon: "立即下载",
          success: "注册成功！",
          pRead: "请先阅读并同意用户协议和隐私政策！",
          numNone: "手机号/邮箱不能为空！",
          numNone_dp: "邮箱不能为空！",
          codeSend: "验证码已发送",
          numIs: "请输入正确的手机号/邮箱",
          numIs_dp: "请输入正确的邮箱",
          tryAgain: "请稍后重试！",
          codeNone: "验证码不能为空",
          passwordNone: "密码不能为空",
          passwordReenter: "您的密码包含空格等非法字符，请重新输入",
          passwordConfirm: "请输入确认密码",
          passwordSame: "两次输入密码不一致",
          passwordLegal:
            "使用6-18个字符，至少需含有以下类别其中两类：字母、数字、特殊字符。",
          wechat: require("@/common/img/webdown.png"),
          topText: {
            title1: "欢迎加入DeTok",
            title2: "全球大家庭",
            text1: "欢迎您加入",
            text2: "DeTok全球社群",
            text3: "，和全球",
            text4: "1200万",
            text5: "用户共同开启Web3短视频新纪元。成功完成注册，您和邀请人将各自获得",
            text6: "1000枚原子币和100原力。"
          }
        },
        "zh-TW": {
          title: "註冊",
          logo: require("@/common/img/logo_en.png"),
          name: require("@/common/img/name_en.png"),
          placeholder: "輸入電子郵件",
          placeholder_dp: "輸入電子郵件",
          placeholder1: "請輸入驗證碼",
          placeholder2: "請設置密碼",
          placeholder3: "請再次輸入密碼",
          button: "獲取驗證碼",
          buttons: "s重新獲取",
          inviteDes: "邀請碼",
          inviteP: "請輸入邀請碼",
          register: "註冊",
          ios: "IOS版本研發中,敬請期待!",
          text: "閱讀並同意",
          text1: "【用戶協議】",
          text2: "和",
          text3: "【隱私政策】",

          password: "兩次密碼輸入不一致，請重新輸入",
          Reminder: "提示",
          registerA:
            "您已經註冊過「DeTok」賬號，無需再次註冊！點擊下方，下載「DeTok」APP",
          dSoon: "立即下載",
          success: "註冊成功！",
          pRead: "請先閱讀並同意用戶協議和隱私政策！",
          numNone: "手機號/郵箱不能為空！",
          numNone_dp: "郵箱不能為空！",
          codeSend: "驗證碼已發送",
          numIs: "請輸入正確的手機號/郵箱",
          numIs_dp: "請輸入正確的郵箱",
          tryAgain: "請稍後重試！",
          codeNone: "驗證碼不能為空",
          passwordNone: "密碼不能為空",
          passwordReenter: "您的密碼包含空格等非法字符，請重新輸入",
          passwordConfirm: "請輸入確認密碼",
          passwordSame: "兩次輸入密碼不一致",
          passwordLegal:
            "使用6-18個字符，至少需含有以下類別其中兩類：字母、數字、特殊字符。",
          wechat: require("@/common/img/webdown_tw.png"),
          topText: {
            title1: "歡迎加入DeTok",
            title2: "全球大家庭",
            text1: "歡迎您加入",
            text2: "DeTok全球社群",
            text3: "，和全球",
            text4: "1200万",
            text5: "1200萬用戶共同開啟Web3 短視頻新紀元。成功完成註冊，您和邀請人將各自獲得",
            text6: "1000枚原子幣和100原力。"
          }
        },
        "en": {
          title: "Sign Up",
          logo: require("@/common/img/logo_en.png"),
          name: require("@/common/img/name_en.png"),
          placeholder: "Enter email",
          placeholder_dp: "Enter email",
          placeholder1: "Enter verification code",
          placeholder2: "Set login password",
          placeholder3: "Please set login password",
          button: "Send",
          buttons: "s",
          inviteDes: "Invitation code",
          inviteP: "Please set invitation code",
          register: "Sign up",
          ios: "IOS Under Development",
          text: "Read and agree to",
          text1: "User Agreement",
          text2: "and",
          text3: "Privacy Policy",

          password: "Two passwords are inconsistent.Please reenter.",
          Reminder: "Reminder",
          registerA:
            "The account has been registered. Go ahead to download DeTok APP.",
          dSoon: "Download",
          success: "Successful",
          pRead: "Please read and agree to User Agreement and Privacy Policy",
          numNone: "Please enter email",
          numNone_dp: "Please enter email",
          codeSend: "Sent successfully",
          numIs: "Please enter correct email",
          numIs_dp: "Please enter correct email",
          tryAgain: "Please try again later",
          codeNone: "Please enter verification code",
          passwordNone: "Please enter password",
          passwordReenter: "The password is against rule. Please reenter.",
          passwordConfirm: "Please reenter password",
          passwordSame: "Two passwords are inconsistent",
          passwordLegal:
            "6-18 characters, a combination of two or three categories: letters, numbers, and special characters.",
          wechat: require("@/common/img/webdown_en.png"),


          topText: {
            title1: "Welcome to DeTok ",
            title2: "Global family",
            text1: "Welcome to you to join ",
            text2: "the DeTok global commun",
            text3: " and start a new era of Web3 short video with over ",
            text4: "12 million ",
            text5: "users around the world. Upon successful registration, you and the inviter will each receive ",
            text6: "1000 ATOS coins and 100 Force"
          }
        },
      },
      language: "en",
      focus: true,
      params_scatter: null, // 撒币大会注册参数
      cgIcon: require('@/common/img/cg.png'),
      cgDpIcon: require('@/common/img/cg_dp.png'),
      dlIcon: require('@/common/img/iconInfo.png'),
      dlDpIcon: require('@/common/img/iconInfo_dp.png'),
      appName: "dkPlush",
      pwdIcon: "closed-eye",
      pwdIcon1: "closed-eye",
      pwdType: "password",
      pwdType1: "password"
    };
  },
  computed: {
    cgImg() {
      return this.appName == 'dkPlush' ? this.cgDpIcon : this.cgIcon;
    },
    download() {
      return this.appName == 'dkPlush' ? this.dlDpIcon : this.dlIcon;
    }
  },
  methods: {

    pwdClick() {
      this.pwdIcon = this.pwdIcon == "closed-eye" ? "eye-o" : "closed-eye";
      this.pwdType = this.pwdType == "password" ? "text" : "password";
    },

    pwdClick1() {
      this.pwdIcon1 = this.pwdIcon1 == "closed-eye" ? "eye-o" : "closed-eye";
      this.pwdType1 = this.pwdType1 == "password" ? "text" : "password";
    },

    fieldFocus() {
      this.focus = false;
    },

    fieldBlur() {
      this.focus = true;
    },

    // 浏览器打开
    controlBrower() {
      this.browerFlag = !this.browerFlag;
    },

    // 下载 乐刷App
    open() {
      // this.handleNoAndroid();
      // if (this.isAndroid) {
      //   window.location.href =
      //     this.appName == "dkPlush" ? enums.apkUrl_flutter : enums.DeTokAddress;
      // }
      this.$router.go(-1);
    },

    //激活用户
    register() {
      if (!this.passwordCheck()) {
        return;
      }
      let sha256 = require("js-sha256").sha256, //sha256.hmac(密钥,密码)
        password = sha256.hmac(enums.sha256Key, this.params.password);
      let params = {
        userMobile: this.authInfo.userMobile,
        verCode: this.params.verCode,
        loginPassword: password,
        inviteCode: this.inviteCode,
      };
      if (this.params_scatter) {
        params['sabiReward'] = JSON.stringify(this.params_scatter);
      }
      let data = this.handleParams(params),
        _this = this;
      $.ajax({
        type: "POST",
        url: baseApi + "/api/user/register",
        headers: {
          "Content-Type": "application/json",
          appType: "h5",
          appName: this.appName,
          appVersion: "1.0.1",
          deviceToken: "token",
          "Accept-Language": this.language,
        },
        data: JSON.stringify(data),
        dataType: "json",
        success: function (res) {
          console.log("data....", res);
          if (res.code == 100) {
            // let resData = res.data;
            // console.log('data....', resData.code);
            _this.showDownload = true;
          } else {
            _this.tos(res.msg);
          }
        },
      });

      // server.registerMobile(params)
      // .then(res=>{
      //   if (res.data.code == 100)  {
      //     this.showDownload = true;
      //   } else {
      //     this.tos(res.data.msg);
      //   }
      // })
    },

    // 吐司
    tos(test) {
      Toast({ message: test, position: "top", duration: 2000 });
    },

    // 检查
    passwordCheck() {
      if (!this.authInfo.userMobile) {
        this.tos(this.appName == 'dkPlush' ? this.languageConfig[this.language].numIs_dp : this.languageConfig[this.language].numIs);
        return false;
      }
      if (!this.checkEmailTel(this.authInfo.userMobile)) {
        this.tos(this.appName == 'dkPlush' ? this.languageConfig[this.language].numIs_dp : this.languageConfig[this.language].numIs);
        return false;
      }
      if (!this.params.verCode) {
        this.tos(this.languageConfig[this.language].codeNone);
        return false;
      }
      if (!this.params.password) {
        this.tos(this.languageConfig[this.language].passwordNone);
        return false;
      }
      var regx1 = /^(?=.*\d)(?=.*[a-zA-Z])(?![^\da-zA-Z]+$).{6,18}$/;
      if (!regx1.test(this.params.password)) {
        this.tos(this.languageConfig[this.language].passwordLegal);
        return false;
      }
      var regx2 =
        /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9_`~!@#$%^&*()+=|{}':;',\\\\\].<>/?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、？]{6,18}$/;
      if (!regx2.test(this.params.password)) {
        this.tos(this.languageConfig[this.language].passwordReenter);
        return false;
      }
      if (this.params.password.indexOf(" ") != -1) {
        this.tos(this.languageConfig[this.language].passwordReenter);
        return false;
      }

      if (!this.params.checkPassword) {
        this.tos(this.languageConfig[this.language].passwordConfirm);
        return false;
      }
      if (this.params.password != this.params.checkPassword) {
        this.tos(this.languageConfig[this.language].passwordSame);
        return false;
      }
      if (!this.checked) {
        this.toastOperate();
        return false;
      }
      return true;
    },

    // 腾讯验证码
    getCode() {
      if (!this.authInfo.userMobile) {
        this.tos(this.appName == 'dkPlush' ? this.languageConfig[this.language].numNone_dp : this.languageConfig[this.language].numNone);
        return;
      }
      let _this = this;
      // 腾讯防水墙
      captcha1 = new window.TencentCaptcha(
        document.getElementById("TencentCaptchaBtn"),
        "2031103404",
        function (res) {
          if (res.ticket && res.randstr) {
            _this.params.randstr = res.randstr;
            _this.params.ticket = res.ticket;
            // 成功，发送验证码
            _this.sendCode();
          } else {
            _this.params.randstr = "";
            _this.params.ticket = "";
          }
        },
        { userLanguage: this.handleLanguage() }
      );
      captcha1.show();
    },

    handleLanguage() {
      if (this.language === "zh-TW") {
        return "zh-tw";
      } else if (this.language === "zh-CN") {
        return "zh-cn";
      } else if (this.language === "en") {
        return "en";
      }
    },

    // 定时器
    changeNum(value) {
      this.time = value;
      this.timer = setInterval(() => {
        this.time--;
        if (this.time <= 1) {
          clearInterval(this.timer);
          this.codeShow = true;
        }
      }, 1000);
    },

    // 发送验证码
    sendCode() {
      let returnInfo = this.handleVerReturn();
      if (returnInfo.noPass) {
        this.tos(returnInfo.toast);
        return;
      }
      let sendParams = {
        userMobile: this.authInfo.userMobile,
        ip: "",
        ticket: this.params.ticket,
        randomStr: this.params.randstr,
      };
      let data = this.handleParams(sendParams),
        _this = this;
      this.codeShow = false;
      if (this.authInfo.userMobile.indexOf("@") == -1) {
        this.changeNum(60);
        // server.sendPhoneCode(sendParams)
        // .then((res) => {
        //     console.log('res...', res);
        //     if (res.data.code == 100)
        //     {
        //         this.tos(this.languageConfig[this.language].codeSend);
        //     }
        //     else
        //     {
        //         this.tos(res.data.msg);
        //     }
        // })

        $.ajax({
          type: "POST",
          url: baseApi + "/api/sendMsg/smsPhoneValidate",
          headers: {
            "Content-Type": "application/json",
            appType: "h5",
            appName: this.appName,
            appVersion: "1.0.1",
            deviceToken: "token",
            "Accept-Language": this.language,
          },
          data: JSON.stringify(data),
          dataType: "json",
          success: function (res) {
            console.log("data....", res);
            if (res.code == 100) {
              // let resData = res.data;
              // console.log('data....', resData.code);
              _this.tos(_this.languageConfig[_this.language].codeSend);
            } else {
              _this.tos(res.msg);
            }
          },
        });
      } else {
        this.changeNum(120);
        // server.sendEmailCode(sendParams)
        // .then((res) => {
        //     if (res.data.code == 100)
        //     {
        //         this.tos(this.languageConfig[this.language].codeSend);
        //     }
        //     else
        //     {
        //         this.tos(res.data.msg);
        //     }
        // })

        $.ajax({
          type: "POST",
          url: baseApi + "/api/sendMsg/smsEmailValidate",
          headers: {
            "Content-Type": "application/json",
            appType: "h5",
            appName: this.appName,
            appVersion: "1.0.1",
            deviceToken: "token",
            "Accept-Language": this.language,
          },
          data: JSON.stringify(data),
          dataType: "json",
          success: function (res) {
            console.log("data....", res);
            if (res.code == 100) {
              // let resData = res.data;
              // console.log('data....', resData.code);
              _this.tos(_this.languageConfig[_this.language].codeSend);
            } else {
              _this.tos(res.msg);
            }
          },
        });
      }
    },

    // 先检测用户信息、再获取验证码
    checkStatus() {
      let returnInfo = this.handleVerReturn();
      if (returnInfo.noPass) {
        this.tos(returnInfo.toast);
        return;
      }
      let dataMobile = {
        userMobile: this.authInfo.userMobile,
      };
      let data = this.handleParams(dataMobile),
        _this = this;
      // server.registerMobileIsExist(dataMobile).then(res => { // 获取是否已注册
      //   if (res.data.code === 100) {
      //     let isExist = res.data.data.isExistUserMobile;
      //     // 1 不存在 2 存在
      //     if (isExist == 1) {
      //       this.getCode();
      //     } else {
      //       this.show = true;
      //     }
      //   } else {
      //     this.tos(res.data.msg);
      //   }
      // })

      $.ajax({
        type: "POST",
        url: baseApi + "/api/user/isExistUserMobile",
        headers: {
          "Content-Type": "application/json",
          appType: "h5",
          appName: this.appName,
          appVersion: "1.0.1",
          deviceToken: "token",
          "Accept-Language": this.language,
        },
        data: JSON.stringify(data),
        dataType: "json",
        success: function (res) {
          console.log("data....", res);
          if (res.code == 100) {
            // let resData = res.data;
            // console.log('data....', resData.code);
            let isExist = res.data.isExistUserMobile;
            // 1 不存在 2 存在
            if (isExist == 1) {
              _this.getCode();
            } else {
              _this.show = true;
            }
          } else {
            _this.tos(res.msg);
          }
        },
      });
    },

    // 检验是否可以发验证码
    handleVerReturn() {
      let noPass = false,
        toast;
      if (!this.authInfo.userMobile) {
        noPass = true;
        toast = this.appName == 'dkPlush' ? this.languageConfig[this.language].numNone_dp : this.languageConfig[this.language].numNone;
      } else if (!this.checkEmailTel(this.authInfo.userMobile)) {
        noPass = true;
        toast = this.appName == 'dkPlush' ? this.languageConfig[this.language].numIs_dp : this.languageConfig[this.language].numIs;
      } else if (!this.codeShow) {
        noPass = true;
        toast = this.languageConfig[this.language].tryAgain;
      }
      return { noPass, toast };
    },

    //  校验邮箱
    checkEmailTel(str) {
      let pass = false;
      var reg_dt = /(^(\w-*\.*)+@(\w-?)+(\.\w{2,})+)|(^1\d{10})$/,
        reg_dp = /(^(\w-*\.*)+@(\w-?)+(\.\w{2,})+)$/,
        reg = this.appName == 'dkPlush' ? reg_dp : reg_dt;
      if (reg.test(str)) {
        pass = true;
      }
      return pass;
    },

    // 服务器错误显示遮罩，不可操作
    overlayShow() {
      this.showOverlay = true;
    },

    // 点击按钮获取焦点
    inputFocus() {
      this.$nextTick(function () {
        //DOM 更新了
        this.$refs["inputMobile"].focus();
      });
    },

    jump(value) {
      let path;
      if (value == "userAgreement") {
        if (this.language == "zh-CN" || this.language == "zh-TW") {
          path = "/userAgreementDetok?language=" + this.language;
        } else {
          path = "/userAgreementDetokEn";
        }
      } else {
        if (this.language == "zh-CN" || this.language == "zh-TW") {
          path = "/privacyPolicyDetok?language=" + this.language;
        } else {
          path = "/privacyPolicyDetokEn";
        }
      }
      window.location.href = "https://h5.leshua.info/#" + path;
      // this.$router.push({
      //     path,
      // })
    },

    toastOperate() {
      Toast({
        message: this.languageConfig[this.language].pRead,
        duration: 2000,
      });
    },

    // 判断客户端
    handleAgent() {
      var u = window.navigator.userAgent;
      this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // IOS
      this.isWeixin = !!/MicroMessenger/i.test(u); // weixin浏览器
    },

    // 处理不能跳转情况
    handleNoAndroid() {
      if (this.isWeixin) {
        this.controlBrower();
      } else if (this.isiOS) {
        if (this.appName == 'dkPlush') {
          window.location.href = enums.appStore;
        } else {
          this.iosTos()
        }
      }
    },

    // ios提示
    iosTos() {
      Toast({
        message: this.languageConfig[this.language].download,
        duration: 3000,
      });
    },

    handleParams(params) {
      ////生成唯一随机字符串(UUID 去中划线-)
      let appNonce = this.uuidMiddle();
      /// 生成当前时间时间戳
      let appTimeStamp = "";
      if (
        window.Android &&
        this.isHostMethod(window.Android, "getServiceTime")
      ) {
        appTimeStamp = Number(window.Android.getServiceTime());
      } else if (window.time) {
        appTimeStamp = window.time;
      } else {
        appTimeStamp = Date.parse(new Date());
      }
      // if (window.time) {
      //     appTimeStamp = window.time
      // } else {
      //     appTimeStamp = Date.parse(new Date())
      // }
      // 签名处理
      // -------测试生成appSign-------
      params.appTimeStamp = appTimeStamp;
      params.appNonce = appNonce;
      let paramsAfter = this.sortASCII(params);
      // 盐值处理
      // var decrypt = CryptoJS.AES.decrypt(
      //     enums.signSaltKey,
      //     CryptoJS.enc.Utf8.parse(enums.aseKey),
      //     {
      //         mode: CryptoJS.mode.ECB,
      //         padding: CryptoJS.pad.Pkcs7,
      //     }
      // ).toString(CryptoJS.enc.Utf8);
      paramsAfter.signSercetKey = (this.appName == 'dkPlush' ? enums.signSaltKey_dkPlush : enums.signSaltKey) + appNonce;
      let stringAfter = "";
      for (let key in paramsAfter) {
        stringAfter = stringAfter + ("&" + key + "=" + paramsAfter[key]);
      }
      stringAfter = stringAfter.substring(1);
      let appSign = md5(stringAfter).toLocaleUpperCase();
      params.appTimeStamp = appTimeStamp;
      params.appNonce = appNonce;
      params.appSign = appSign;

      return params;
    },

    uuidMiddle() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      // uuid 中间无-
      s[8] = s[13] = s[18] = s[23] = "";
      var uuid = s.join("");
      return uuid;
    },

    sortASCII(obj) {
      var arr = new Array();
      var num = 0;
      for (var i in obj) {
        arr[num] = i;
        num++;
      }
      var sortArr = arr.sort();
      var sortObj = {};
      for (var j in sortArr) {
        sortObj[sortArr[j]] = obj[sortArr[j]];
      }
      return sortObj;
    },

    isHostMethod(object, property) {
      var t = typeof object[property];
      return (
        t == "function" ||
        !!(t == "object" && object[property]) ||
        t == "unknown"
      );
    },
  },
  created() {
    if (this.$route.query.inviteCode) {
      this.inviteCode = this.$route.query.inviteCode;
    }

    // if (this.$route.query.language) {
    //   this.language = this.$route.query.language;
    //   // window.acceptLanguage = this.language;
    //   this.appName = this.$route.query.appName || "DeTok";
    //   document.title = this.languageConfig["" + this.language].title;
    // }
    this.language = handleSysLang();
    document.title = this.languageConfig["" + this.language].title;

    if (Reflect.has(this.$route.query, 'params')) { // 撒币大会活动注册添加records和活动id
      this.params_scatter = JSON.parse(this.$route.query.params);
    }
  },
  mounted() {
    this.handleAgent();
  },
  updated() {
    window.onresize = () => {
      let body = document.getElementById("register");
      body.style.overflowY = "auto";
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
#register {
  flex: auto;
  /* padding: 1.65rem 0.34rem 0; */
  color: #999;
  font-size: 0.2rem;
  /* background: url('../../../common/img/bg.png') no-repeat center; */
  background-size: cover;

  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  background: #fff;
}

.center {
  padding: 0 8vw; box-sizing: border-box;
}
.center .phone {
  margin-bottom: 0.5rem;
  font-size: 0.48rem;
  color: #fff;
}
.center .inviteCode {
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
  font-size: 0.32rem;
  color: #1b1b1b;
}
.van-cell {
  height: 13.3vw;margin-bottom: 5vw;
  padding: 0 4vw !important;box-sizing: border-box;
  /* line-height: 13.3vw; */
  border-bottom: none;display: flex;align-items: center;
  background: #F5F5F5;
  border-radius: 2vw;font-size: 4vw;color: #333333;
}
/deep/.van-field__control::-webkit-input-placeholder {
  color: #999999;font-size: 4vw;
}
.van-cell::after {
  left: 0;
  border: none;
}

.registerInput {
  position: relative;margin-top: 8vw;
}
.checkCode {
  position: absolute;
  right: 10vw;
  top: 20vw;
  display: inline-block;
  width: 1.9rem;
  height: 8.8vw;
  background: #fc264a;
  border: 0.02rem solid none;
  border-radius: 0.08rem;
  line-height: 8.8vw;
  text-align: center;
  font-size: 0.28rem;
  color: #fff;
}
.checkCode_dp {
  position: absolute;
  right: 4vw;
  top: 21vw;
  display: inline-block;
  /* width: 1.9rem; */
  height: 8.8vw;
  /* background: linear-gradient(to right, #91f0fe, #967ce4); */
  /* border: 0.02rem solid none; */
  /* border-radius: 0.08rem; */
  line-height: 8.8vw;
  text-align: center;
  font-size: 4vw;
  color: #0F83FF;
}
.time {
  /* width: 1.9rem;
  height: 8.8vw; */
  box-sizing: border-box;
  /* padding: 0.18 0.26rem; */
  position: absolute;
  right: 4vw;
  top: 21vw;
  display: inline-block;
  /* background: #f4f4f4;
  border-radius: 0.08rem; */
  line-height: 8.8vw;
  text-align: center;
  font-size: 4vw;
  color: #333333;
}
.button {
  height: 12vw;
  line-height: 12vw;
  border-radius: 0.08rem;
  text-align: center;
  font-size: 4vw;
  color: #fff;font-weight: bolder;
  margin-top: 3vw;
  display: flex;align-items: center;justify-content: center;
  /* margin: 0.3rem 0; */
}
.dialog .wrapper {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  background: #ffffff;
  border-radius: 0.2rem;
  padding: 8.5vw 4vw;box-sizing: border-box;
  text-align: center;
}
.dialog .tips h3 {
  font-size: 0.34rem;
  font-weight: bold;
  margin-top: 0.4rem;
  margin-bottom: 0.26rem;
  color: #1a1a1a;
}
.dialog .tips img {
  width: 2rem;
  height: 2rem;
}
.dialog .tips p {
  font-size: 0.28rem;
  color: #1a1a1a;
  line-height: 0.42rem;
  text-align: left;
}
.dialog .tips .down {
  margin-top: 0.4rem;
  width: 100%;
  height: 0.8rem;
  text-align: center;
  background: linear-gradient(-90deg, #ff6f4d, #fc1849);
  border-radius: 0.08rem;
  font-size: 0.32rem;
  line-height: 0.8rem;
  color: #fff;
}
.dialog .tips .downDp {
  margin-top: 0.4rem;
  width: 100%;
  height: 12.8vw;
  text-align: center;
  background: #7949FF;
  border-radius: 0.08rem;
  font-size: 0.32rem;
  line-height:12.8vw;
  color: #fff;font-weight: bold;
}
.editClass {
  position: absolute;
  right: 0.34rem;
  top: 0.08rem;
  display: inline-block;
  width: 1.9rem;
  height: 0.58rem;
  /* background: rgba(255, 255, 255, 0.1); */
  border: 0.02rem solid none;
  border-radius: 0.08rem;
  line-height: 0.58rem;
  text-align: center;
  font-size: 0.28rem;
  color: #fff;
}

.footer {
  /* margin-top: 0.5rem; */
  /* position: fixed;
  bottom: 0.8rem; */
  width: 100%;
  /* margin-bottom: 0.3rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.2vw;
  text-align: center;
  margin-top: 13.5vw;
}
.footer span {
  font-size: 0.24rem;
  color: #999;
}

.van-checkbox {
  margin-right: 0.12rem;
}

.footer .note {
  color: #fd504d;
}

.footer .note_dp {
  color: #7949FF;
}

.note {
  margin-top: 0.18rem;
  font-size: 0.26rem;
}

.note_dp {
  margin-top: 0.18rem;
  font-size: 0.26rem;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  max-width: 70%;
  min-height: 50px;
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
  /* background-color: rgba(0,0,0,.7); */
  background: linear-gradient(-90deg, #ff6f4d, #fc1849);
  color: #fff;
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  width: -webkit-fit-content;
  width: fit-content;
  /* min-width: 96px;
    min-height: 0; */
  padding: 8px 12px;
  z-index: 2001;
}

>>> .van-checkbox__icon {
  font-size: 3.2vw;
}

.img_brower {
  width: 32vw;
  position: absolute;
  right: 5.3vw;
}


/* .register .registerInput .van-cell__value--alone,
.register .registerInput .van-field__control {
  margin-top: 2vw;
  font-size: 0.32rem;
  color: #1b1b1b !important;
} */

.btn_dis {
  background: gray;
}
.btn_yes {
  background: linear-gradient(-90deg, #ff6f4d, #fc1849);
}
.btn_dp {
  background: linear-gradient(to right, #91f0fe, #967ce4);
}






.flexable {
  display: flex;align-items: center;
}

.registerTop {
  margin-top: 8vw;
}

.linearFont {
  width: 60vw;
  height: 16vw;
  font-size: 6.4vw;
  font-weight: bold;
  display: flex;align-items: center;
  color: transparent;margin-left: 5.2vw;text-wrap: nowrap;
  background: linear-gradient(to right, #0F83FF 0%, #904FD2 80%);
  -webkit-background-clip: text;-webkit-text-fill-color: transparent;
}

.colorB {
  color: #0F83FF;
}

/deep/.van-checkbox__icon--checked .van-icon {
  background-color: #0F83FF !important;border-color: #0F83FF !important;
}

</style>
